import { useMemo } from "react";
import { useParams } from "react-router";

export default function useRedirectUrl(toSuccessPage: boolean = false) {
    const { organizationId, campaignId, ambassadorId } = useParams();
    const redirectUrl = useMemo(() => {
        let url = `/public/organizations/${organizationId}`;
        if (campaignId) {
            url += `/campaigns/${campaignId}`;
        }
        if (ambassadorId) {
            url += `/ambassadors/${ambassadorId}`;
        }
        return url;
    }, [organizationId, campaignId, ambassadorId]);

    return redirectUrl + (toSuccessPage ? "/donation-successful" : "");
}