import BackIcon from "@/svg/chevron-left.svg?react";
import React from "react";
import { Link } from "react-router-dom";

export default function BackButton({ to }: {
    to: string;
}) {


    return (
        <Link to={to} className="size-10 flex items-center justify-center rounded-full interactive-bg-surface">
            <BackIcon className="size-7 fill-on-surface" />
        </Link>
    )
}