import { EmailRegex } from "@/js/common"
import Button from "@/js/Components/Form/Button"
import Input from "@/js/Components/Form/Input"
import { Form, SubmitHandler } from "@enymo/react-form-component"
import axios from "axios"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { route } from "ziggy-js"

interface Submit {
    email: string;
}

export default function ForgotPassword() {
    const form = useForm<Submit>();
    const [emailSent, setEmailSent] = useState(false);
    const handleSubmit: SubmitHandler<Submit> = async (data) => {
        await axios.post(route("users.forgot-password"), data);
        setEmailSent(true);
    }
    return (
        <div className="flex flex-col gap-3">
            {emailSent ? (
                <h1 className="ttl-l text-center text-primary">A jelszóemlékeztető e-mailt elküldtük, kérjük ellenőrizd az e-mail fiókod!</h1>
            ) : (
                <div className="flex flex-col gap-5 bg-surface-container p-5 rounded-2xl">
                    <h2 className="ttl-m">Jelszóemlékeztető</h2>
                    <Form form={form} onSubmit={handleSubmit} className="flex flex-col gap-4 px-1">
                        <Input label="E-mail cím" name="email" options={{
                            required: "E-mail cím megadása kötelező",
                            pattern: {
                                value: EmailRegex,
                                message: "Helytelen e-mail cím formátum"
                            }
                        }} />
                        <div className="flex justify-center">
                            <Button variant="filled" submit>Jelszóemlékeztető kérése</Button>
                        </div>
                    </Form>
                </div>
            )}
            <div className="flex justify-center">
                <Link className="interactive-bg-surface p-2 rounded-md" to="/login">Vissza a bejelentkezéshez</Link>
            </div>
        </div>
    )
}