import Button from "@/js/Components/Form/Button";
import Input from "@/js/Components/Form/Input";
import PasswordInput from "@/js/Components/Form/PasswordInput";
import { useUser } from "@/js/Providers/UserProvider";
import { EmailRegex } from "@/js/common";
import { Checkbox } from "@/js/glidespec";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { requireNotNull } from "@enymo/ts-nullsafe";
import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

interface Submit {
    email: string;
    password: string;
    remember: boolean;
}

export default function Login() {
    const form = useForm<Submit>();
    const { login } = requireNotNull(useUser());

    const handleSubmit: SubmitHandler<Submit> = async (data) => {
        await login(data.email, data.password, data.remember);
    }
    return (
        <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-5 bg-surface-container p-10 rounded-2xl">
                <h2 className="hd-s">Bejelentkezés</h2>
                <Form form={form} onSubmit={handleSubmit} className="flex flex-col gap-4 px-1">
                        <Input label="E-mail cím" name="email" type="email" options={{
                            required: "E-mail cím megadása kötelező",
                            pattern: {
                                value: EmailRegex,
                                message: "Helytelen e-mail cím formátum"
                            }
                        }} />
                        <PasswordInput label="Jelszó" name="password" options={{
                            required: "Jelszó megaadása kötelező"
                        }} />
                        <Checkbox name="remember">
                            <span>Bejelentkezve maradok</span>
                        </Checkbox>
                        <div className="flex justify-center">
                            <Button variant="filled" submit>Bejelentkezés</Button>
                        </div>
                </Form>
            </div>
            <div className="flex justify-center">
                <Link className="interactive-bg-surface p-2 rounded-md" to="/login/forgot-password">Elfelejtettem a jelszavam</Link>
            </div>
        </div>
    )
}