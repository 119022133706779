import CheckIcon from "@/svg/check.svg?react";
import { createGlideChoice } from "@enymo/glide";
import React from "react";


export const Checkbox = createGlideChoice({
    indicator: {
        style: {
            width: "26px",
            height: "26px",
            border: "1px solid rgb(var(--color-outline-variant))",
            borderRadius: "8px",
            background: "rgb(var(--color-surface))"
        },
        selectedStyle: {
            borderColor: "rgb(var(--color-primary))",
            background: "rgb(var(--color-primary))"
        },
        element: <CheckIcon width="18px" fill="rgb(var(--color-on-primary))" />
    },
    childrenGap: "10px",
    labelStyle: {
        margin: "5px 0",
        fontSize: "14px",
        color: "rgb(var(--color-on-surface-variant))"
    },
    wrapperStyle: {
        cursor: "pointer"
    },
})