import React from "react";
import { Link } from "react-router-dom";

export default function Error({
    type = "404",
}: {
    type?: "404" | "403",
}) {
    return (
        <div className="flex flex-col flex-1 bg-surface p-10 items-center gap-5 justify-center">
            <h1 className="hd-s text-center text-primary">{(() => {
                switch (type) {
                    case "404":
                        return "Úgy tűnik, hogy a keresett oldal nem található..."
                    case "403":
                        return "Úgy tűnik, hogy nincs jogosultságod az oldal megtekintéséhez..."
                }
            })()}</h1>
            <Link to="/public" className="p-1 interactive-bg-surface-container rounded-md">Visszatérés a főoldalra</Link>
        </div>
    )
}