import useHybridInput from "@enymo/react-hybrid-input-hook";
import React from "react";
import { RegisterOptions } from "react-hook-form";

export default function Switch({
    name,
    value: externalValue,
    onChange: externalOnChange,
    disabled,
    options
}: {
    name?: string,
    value?: boolean,
    onChange?: (value: boolean) => void,
    options?: RegisterOptions,
    disabled?: boolean
}) {
    const { value, onChange } = useHybridInput({ name, externalValue, externalOnChange, options });
    return (
        <label className={`
            block relative w-[52px] h-[32px] rounded-full border-2 cursor-pointer transition-[background]
            ${disabled && "disabled"}
            ${value ? "border-primary interactive-bg-primary" : "border-outline interactive-bg-surface-container"}
        `}>
            <div className={`
                absolute top-[6px] left-[6px] size-[16px] rounded-full bg-outline transition-all
                ${value && "!size-[24px] !top-[2px] !left-[24px] !bg-on-primary"}    
            `} />
            <input className="hidden" type="checkbox" checked={value} onChange={() => onChange(!value)} disabled={disabled} />
        </label>
    )
}