import Button from "@/js/Components/Form/Button";
import Input from "@/js/Components/Form/Input";
import { usePopup } from "@/js/Components/Popup";
import NewCampaignPopup from "@/js/Components/Popups/NewCampaignPopup";
import Switch from "@/js/Components/Switch";
import TableEmptyPlaceholder from "@/js/Components/TableEmptyPlaceholder";
import TableLoadingPlaceholder from "@/js/Components/TableLoadingPlaceholder";
import { toCurrency } from "@/js/common";
import { useCampaigns } from "@/js/resources";
import SearchIcon from "@/svg/search.svg?react";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

export default function Campaigns() {
    const [campaigns, { loading, store, update }] = useCampaigns({
        sorter: (a, b) => a.created_at.getMilliseconds() > b.created_at.getMilliseconds() ? 1 : -1,
    });
    const { addPopup, popPopup } = usePopup();
    const navigate = useNavigate();
    const [search, setSearch] = useState("");

    const filteredCampaigns = useMemo(
        () => campaigns.filter(campaign => campaign.name.toLowerCase().includes(search.toLowerCase())), 
        [campaigns, search]
    )

    const handleNewCampaign = () => {
        addPopup(<NewCampaignPopup onSubmit={async (data) => {
            const campaign = await store(data);
            popPopup();
            navigate(`/app/campaigns/${campaign.id}`);
        }}/>);
    }

    return (
        <div className="flex flex-col flex-1 max-w-[1200px] gap-8 py-10 w-full self-center">
            <div className="flex items-center justify-between gap-3">
                <Button variant="filled-tonal" onClick={handleNewCampaign}>
                    + Új kampány
                </Button>
                <Input prefix={<SearchIcon />} placeholder="Keresés..." value={search} onChange={setSearch} />
            </div>
            <SimpleBar style={{ overflowX: "auto", width: "100%" }}>
                <table className="w-full">
                    <thead className="bg-primary-container [&_th]:p-3 text-left first:[&_th]:rounded-tl-[8px] last:[&_th]:rounded-tr-[8px]">
                        <tr>
                            <th>Név</th>
                            <th>Státusz</th>
                            <th>Létrehozva</th>
                            <th style={{ textAlign: "right" }}>Adományok összesen</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <TableLoadingPlaceholder colSpan={4} />
                        ) : filteredCampaigns.length > 0 ? filteredCampaigns.map(campaign => (
                            <tr 
                                key={campaign.id} 
                                className="interactive-bg-surface border-b border-b-outline-variant [&_td]:p-3"
                            >
                                <td>
                                    <Link className="font-medium hover:!text-primary" to={`/app/campaigns/${campaign.id}`}>{campaign.name}</Link>
                                </td>
                                <td>
                                    <Switch 
                                        value={campaign.active} 
                                        onChange={() => update(campaign.id, { active: !campaign.active }, "immediate")}
                                    />
                                </td>
                                <td>{campaign.created_at.toLocaleDateString('hu')}</td>
                                <td style={{ textAlign: "right" }}>{toCurrency(campaign.donation_amount)}</td>
                            </tr>
                        )) : (
                            <TableEmptyPlaceholder colSpan={4} />
                        )}
                    </tbody>
                    {filteredCampaigns.length > 0 && (
                        <tfoot className="[&_td]:p-3">
                            <tr>
                                <td colSpan={4} className="bd-l text-right">
                                    Összesen: {toCurrency(filteredCampaigns.reduce((sum, campaign) => sum + campaign.donation_amount, 0))}
                                </td>
                            </tr>
                        </tfoot>
                    )}
                </table>
            </SimpleBar>
        </div>
    )
}