import AmbassadorCards from "@/js/Components/AmbassadorCards";
import BackButton from "@/js/Components/BackButton";
import DonationsTable from "@/js/Components/DonationsTable";
import Button from "@/js/Components/Form/Button";
import IconButton from "@/js/Components/IconButton";
import { ImageInput } from "@/js/Components/ImageInput";
import Loader from "@/js/Components/Loader";
import { usePopup } from "@/js/Components/Popup";
import NewAmbassadorPopup from "@/js/Components/Popups/NewAmbassadorPopup";
import PropertyForm from "@/js/Components/PropertyForm";
import StaticProgressBar from "@/js/Components/StaticProgressBar";
import Switch from "@/js/Components/Switch";
import { useUser } from "@/js/Providers/UserProvider";
import { CAMPAIGN_IMAGE_ASPECT_RATIO, toCurrency } from "@/js/common";
import { useCampaignAmbassadors, useCampaignDonations, useCampaigns } from "@/js/resources";
import OpenInNewIcon from "@/svg/open-in-new.svg?react";
import { Form, SubmitHandler, setFormValues } from "@enymo/react-form-component";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

interface Submit {
    name: string;
    description: string;
    donation_goal: number;
    donation_success_message: string;
    donation_email_template: FileList | null;
    campaign_end_email_template: FileList | null;
}

export default function Campaign() {
    const { user } = requireNotNull(useUser());
    assertNotNull(user);
    const { campaignId } = useParams();
    const [campaign, { update }] = useCampaigns({ id: Number(campaignId) });

    const [donations] = useCampaignDonations({
        params: useMemo(() => ({
            campaign: campaignId,
        }), [campaignId]),
    });

    const [ambassadors, { loading: ambassadorsLoading, store }] = useCampaignAmbassadors({
        params: useMemo(() => ({
            campaign: campaignId,
        }), [campaignId]),
    });

    const { addPopup, popPopup } = usePopup();
    const navigate = useNavigate();

    const [editMode, setEditMode] = useState(false);
    const form = useForm<Submit>();
    
    const [showNotApproved, setShowNotApproved] = useState(false);

    useEffect(() => {
        if (!campaign) return;
        setFormValues(form, {
            ...campaign,
            campaign_end_email_template: undefined,
            donation_email_template: undefined,
        });
    }, [campaign]);

    const handleSubmit: SubmitHandler<Submit> = async (data) => {
        await update({
            ...data,
            campaign_end_email_template: data.campaign_end_email_template === null ? null : data.campaign_end_email_template?.[0] ?? undefined,
            donation_email_template: data.donation_email_template === null ? null : data.donation_email_template?.[0] ?? undefined,
        });
        setEditMode(false);
    }

    const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined);

    const handleFileSelect = (files: File[]) => {
        if (files.length === 0) return;
        const file = files[0];
        update({
            image: { file },
        }, 'immediate', {
            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.progress ?? 0) * 100;
                if (progress < 100) {
                    setUploadProgress(progress);
                }
                else {
                    setUploadProgress(undefined);
                }
            }
        });
    }

    const handleDeleteImage = () => {
        update({
            image: null,
        }, 'immediate');
    }

    const handleAddAmbassador = () => {
        addPopup(
            <NewAmbassadorPopup
                onSubmit={async (data) => {
                    const ambassador = await store(data);
                    popPopup();
                    navigate(`/app/campaigns/${campaignId}/ambassadors/${ambassador.id}`);
                }}
            />
        );
    }

    return (
        <div className="flex flex-col py-5 gap-10 flex-1 max-w-[1200px] w-full self-center">
            {!campaign ? (
                <div className="flex items-center justify-center flex-1">
                    <Loader className="w-12" />
                </div>
            ) : (
                <>
                    <div className="flex justify-between items-center">
                        <div className="flex gap-5 items-center">
                            <BackButton to="/app/campaigns" />
                            <h1 className="hd-s">Kampány kezelése</h1>
                        </div>
                        <IconButton
                            to={`/public/organizations/${user.organization_id}/campaigns/${campaignId}`}
                            linkType="new-tab"
                            variant="filled-tonal"
                        >
                            <OpenInNewIcon />
                        </IconButton>
                    </div>
                    <div className="flex flex-col gap-8">
                        <div className="flex items-center self-end gap-3">
                            <span className="text-[14px]">{campaign.active ? "Aktív" : "Inaktív"}</span>
                            <Switch
                                value={campaign.active}
                                onChange={(active) => update({ active }, 'immediate')}
                            />
                        </div>
                        <div className="flex items-center self-end gap-3">
                            <span className="text-[14px]">{campaign.published ? "Nyilvános" : "Nem nyilvános"}</span>
                            <Switch
                                value={campaign.published}
                                onChange={(published) => update({ published }, 'immediate')}
                            />
                        </div>
                        <ImageInput
                            resourceImage={campaign.image ?? undefined}
                            uploadProgress={uploadProgress}
                            handleFileSelect={handleFileSelect}
                            onDelete={handleDeleteImage}
                            onSetPosition={(position) => update({
                                image: {
                                    ...campaign.image,
                                    position,
                                }
                            })}
                            aspectRatio={CAMPAIGN_IMAGE_ASPECT_RATIO}
                            className="w-full h-min"
                        />
                        <Form form={form} onSubmit={handleSubmit} className="flex flex-col gap-3">
                            <PropertyForm
                                editable={editMode}
                                properties={[
                                    { 
                                        label: "Név", 
                                        value: campaign.name,
                                        input: {
                                            name: "name",
                                            options: { required: "Kötelező mező" }
                                        },
                                    },
                                    { 
                                        label: "Leírás", 
                                        value: campaign.description, 
                                        cutLength: 300,
                                        input: {
                                            name: "description",
                                            type: "textarea",
                                            inputClassName: "min-h-[300px] resize-y",
                                            options: { required: "Kötelező mező" }
                                        }, 
                                    },
                                    { 
                                        label: "Célösszeg", 
                                        value: toCurrency(campaign.donation_goal),
                                        input: {
                                            name: "donation_goal",
                                            type: "number",
                                            options: { required: "Kötelező mező" },
                                            suffix: "Ft",
                                        },
                                    },
                                    {
                                        label: "Adományozás utáni üzenet",
                                        value: campaign.donation_success_message,
                                        cutLength: 200,
                                        input: {
                                            name: "donation_success_message",
                                            type: "textarea",
                                            inputClassName: "min-h-[100px] resize-y",
                                            options: { required: "Kötelező mező" }
                                        }
                                    },
                                    {
                                        label: "Adományozás utáni e-mail",
                                        value: campaign.donation_email_template?.name,
                                        input: {
                                            inputType: "file",
                                            name: "donation_email_template",
                                            label: "{useLabel}",
                                            multiple: false,
                                            accept: "text/html",
                                            fileNames: campaign.donation_email_template?.name,
                                            previewUrl: `/app/campaigns/${campaignId}/preview?template=donation`,
                                        }
                                    },
                                    {
                                        label: "Kampány vége e-mail",
                                        value: campaign.campaign_end_email_template?.name,
                                        input: {
                                            inputType: "file",
                                            name: "campaign_end_email_template",
                                            label: "{useLabel}",
                                            multiple: false,
                                            accept: "text/html",
                                            fileNames: campaign.campaign_end_email_template?.name,
                                            previewUrl: `/app/campaigns/${campaignId}/preview?template=campaign_end`,
                                        }
                                    }
                                ]}
                            />
                            <div className="flex justify-end gap-3">
                                <Button variant="outline" onClick={() => setEditMode(!editMode)}>
                                    {editMode ? "Mégse" : "Szerkesztés"}
                                </Button>
                                {editMode && (
                                    <Button variant="filled-tonal" submit>Mentés</Button>
                                )}
                            </div>
                        </Form>
                        <div className="flex flex-col gap-5">
                            <h1 className="ttl-l text-primary">Adományok</h1>
                            <div className="flex gap-6 items-center">
                                <h2>Összesen: {toCurrency(campaign.donation_amount)}</h2>
                                <div className="flex flex-1">
                                    <StaticProgressBar progress={campaign.donation_amount / campaign.donation_goal * 100} variant="primary" />
                                </div>
                            </div>
                            {donations.length > 0 && (
                                <>
                                    <div className="flex justify-between items-center">
                                        <span>Hibás utalások mutatása</span>
                                        <Switch
                                            value={showNotApproved}
                                            onChange={setShowNotApproved}
                                        /> 
                                    </div>
                                    <DonationsTable donations={donations} showNotApproved={showNotApproved} showEmail showDonee />
                                </>
                            )}
                        </div>
                        <div className="flex flex-col gap-5">
                            <h1 className="ttl-l text-primary">Nagykövetek</h1>
                            <div className="flex">
                                <Button variant="filled-tonal" onClick={handleAddAmbassador}>+ Nagykövet hozzáadása</Button>
                            </div>
                            <AmbassadorCards ambassadors={ambassadors} loading={ambassadorsLoading} />
                        </div>
                    </div>
                </>
            )
            }

        </div>
    );
}