import { createRequiredContext } from "@enymo/react-better-context";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import useScreenSize from "@enymo/react-screen-size-hook";
import React, { useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import SimpleBar from "simplebar-react";

const [Provider, usePopup] = createRequiredContext<{
    addPopup: (popup: React.ReactNode) => void,
    popPopup: () => void,
}>("The PopupParentProvider must be present in the component tree to show a popup.");

export { usePopup };

export function PopupParentProvider({ children }: {
    children: React.ReactNode,
}) {
    const [popupsToShow, setPopupsToShow] = useState<React.ReactNode[]>([]);

    const addPopup = (popup: React.ReactNode) => setPopupsToShow([...popupsToShow, popup]);
    const popPopup = () => setPopupsToShow(popupsToShow.slice(0, -1));

    const handleClick: React.MouseEventHandler = (e) => {
        if (e.target === e.currentTarget) {
            popPopup();
        }
    }

    return (
        <Provider value={{ addPopup, popPopup }}>
            {children}
            {popupsToShow.length !== 0 && (
                <div 
                    className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-popupBg z-50 p-6" 
                    onClick={handleClick}
                >
                    {popupsToShow.map((popup, i) => (
                        <React.Fragment key={i}>
                            {popup}
                        </React.Fragment>
                    ))}
                </div>
            )}
        </Provider>
    )
}

export default function Popup<T extends FieldValues>({
    title,
    children,
    buttons,
    form,
    onSubmit,
    style,
    className,
}: {
    title: string,
    children: React.ReactNode,
    buttons?: React.ReactNode[],
    form?: UseFormReturn<T>,
    onSubmit?: SubmitHandler<T>,
    style?: React.CSSProperties,
    className?: string,
}) {
    const { height } = useScreenSize();
    const inner = (
        <div className="flex flex-col flex-1 gap-6">
            <div className="flex flex-col gap-4" >
                <span className="ttl-l">{title}</span>
                {children}
            </div>
            {buttons && <div className="flex gap-4 justify-end">
                {buttons}
            </div>}
        </div>
    )

    return (
        <div className={`bg-surface-container rounded-[28px] min-w-[280px] max-w-[560px] p-6 max-h-full shrink-0 overflow-hidden ${className ?? ""}`} style={style}>
            <SimpleBar style={{ maxHeight: `${height - 96}px` }}>
                {form ? (
                    <Form onSubmit={onSubmit} form={form} className="flex w-full">
                        {inner}
                    </Form>
                ) : inner}
            </SimpleBar>
        </div>
    )
}