import { Stripe, StripeElements } from "@stripe/stripe-js";
import React, { useCallback } from "react";

export default function useStripeHandleSubmit<T extends {}>(
    onSubmit: (data: T) => Promise<string>,
    onSuccess?: () => void,
    dependencies: React.DependencyList = [],
) {
    const handleSubmit = useCallback((elements: StripeElements | undefined, stripe: Stripe | null) => async (data: T) => {
        if ((await elements?.submit())?.error !== undefined) {
            return;
        }

        const clientSecret = await onSubmit(data);
        await stripe?.confirmPayment?.({
            elements,
            clientSecret,
            redirect: "if_required",
            confirmParams: {
                return_url: `${window.location.href}?redirected=true`,
            }
        })
        onSuccess?.();
    }, dependencies);

    return handleSubmit;
}