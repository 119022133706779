import { SubmitHandler } from "@enymo/react-form-component";
import React from "react";
import { useForm } from "react-hook-form";
import Button from "../Form/Button";
import Input from "../Form/Input";
import Popup from "../Popup";
interface Submit {
    name: string;
    description: string;
    donation_goal: number;
}


export default function NewCampaignPopup({ onSubmit }: {
    onSubmit: SubmitHandler<Submit>
}) {
    
    const form = useForm<Submit>();

    return (
        <Popup title="Új kampány" form={form} onSubmit={onSubmit} buttons={[
            <Button className="interactive-bg-surface-container" variant="text" submit>Mentés</Button>
        ]} className="w-full">
            <div className="flex flex-col gap-4">
                <Input label="Név" name="name" options={{ required: "Kötelező mező" }} />
                <Input label="Leírás" type="textarea" name="description" options={{ required: "Kötelező mező" }} inputClassName="min-h-[150px]"  />
                <Input label="Célösszeg" name="donation_goal" type="number" options={{ 
                    required: "Kötelező mező",
                    min: { value: 0, message: "A célösszeg nem lehet negatív" },
                }} />
            </div>
        </Popup>
    )
}