import React from "react";
import { PositionableImage } from "../resources";
import FileDropzone from "./FileDropzone";
import { Image, PositionRatio } from "./Image";

interface CommonProps {
    uploadProgress?: number,
    handleFileSelect: (files: File[]) => void,
    positionRatio?: PositionRatio,
    onSetPosition?: (postitionRatio: PositionRatio) => void | Promise<void>,
    onDelete: () => void,
    aspectRatio: number,
    axisSet?: "x" | "y",
    className?: string,
}

interface ImageInputProps extends CommonProps {
    image: File | null,
}

interface ResourceImageInputProps extends CommonProps {
    resourceImage?: PositionableImage,
}

interface CombinedImageInputProps extends CommonProps {
    resourceImage?: PositionableImage,
    image?: File | null,
}

export function ImageInput(props: ResourceImageInputProps): JSX.Element;
export function ImageInput(props: ImageInputProps): JSX.Element;

export function ImageInput({
    image,
    resourceImage: resourceImage,
    handleFileSelect,
    aspectRatio,
    axisSet,
    className,
    ...props
}: CombinedImageInputProps) {
    return (resourceImage || image) ? (
        resourceImage ? (
            <Image resourceImage={resourceImage!} aspectRatio={aspectRatio} axisSet={axisSet} className={className} editable {...props} />
        ) : (
            <Image image={image ?? null} aspectRatio={aspectRatio} axisSet={axisSet} className={className} editable {...props} />
        )
    ) : (
        <FileDropzone
            onSelectFile={handleFileSelect}
            className={className}
            aspectRatio={aspectRatio}
            axisSet={axisSet}
        />
    )

}