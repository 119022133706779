import useRedirectOnAxiosError from "@/js/Hooks/AxiosErrorRedirectHook";
import { Ambassador, usePublicCampaignAmbassadors } from "@/js/resources";
import { createRequiredContext } from "@enymo/react-better-context";
import { ReturnSingle } from "@enymo/react-resource-hook";
import React, { useMemo } from "react";
import { Outlet, useParams } from "react-router";

const [Provider, useAmbassador] = createRequiredContext<[Ambassador | null, ReturnSingle<Ambassador, Ambassador>]>("AmbassadorProvider has to be in the tree.");

export { useAmbassador };

export default function AmbassadorProvider() {
    const { campaignId, ambassadorId } = useParams();

    const [ambassador, actions] = usePublicCampaignAmbassadors({
        id: Number(ambassadorId),
        params: useMemo(() => ({
            campaign: campaignId,
        }), [campaignId]),
    });

    useRedirectOnAxiosError(actions.error);

    return (
        <Provider value={[ambassador, actions]}>
            <Outlet />
        </Provider>
    )
}