import { AMBASSADOR_IMAGE_ASPECT_RATIO } from "@/js/common";
import { SubmitHandler } from "@enymo/react-form-component";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../Form/Button";
import Input from "../Form/Input";
import { PositionRatio } from "../Image";
import { ImageInput } from "../ImageInput";
import Popup, { usePopup } from "../Popup";

interface Submit {
    name: string;
    email: string;
    description_long: string;
}

export default function NewAmbassadorPopup({ onSubmit }: {
    onSubmit: (data: Submit & {
        image: {
            file: File | null,
            position?: PositionRatio,
        }
    }) => void | Promise<void>,
}) {
    const form = useForm<Submit>();
    const [image, setImage] = useState<File | null>(null);
    const [imagePosition, setImagePosition] = useState<PositionRatio>();
    const { popPopup } = usePopup();


    const handleSubmit: SubmitHandler<Submit> = async (data) => {
        await onSubmit({
            ...data,
            image: {
                file: image,
                position: imagePosition,
            },
        });
    }

    return (
        <Popup title="Nagykövet hozzáadása" form={form} onSubmit={handleSubmit} className="w-full" buttons={[
            <Button variant="text" onClick={popPopup}>Mégse</Button>,
            <Button variant="filled-tonal" submit>Mentés</Button>
        ]}>
            <div className="flex flex-col gap-8 flex-1">
                <div className="flex flex-col gap-3">
                    <Input label="Név" name="name" options={{
                        required: "Kötelező mező"
                    }} />
                    <Input label="E-mail" name="email" options={{
                        required: "Kötelező mező"
                    }} />
                    <Input label="Leírás" name="description_long" type="textarea" inputClassName="min-h-[150px]" options={{
                        required: "Kötelező mező"
                    }} />
                    <Input label="Célösszeg" name="donation_goal" type="number" options={{
                        min: { value: 0, message: "A célösszeg nem lehet negatív" },
                    }} />
                    <Input label="Kampány vége" name="end_date" type="date" options={{
                        required: "Kötelező mező",
                        validate: (value) => {
                            if (!value) {
                                return "Kötelező mező";
                            }
                            if (new Date(value) < new Date()) {
                                return "A kampány vége nem lehet a múltban";
                            }
                            return true;
                        }
                    }} />
                </div>
                <div className="flex justify-center">
                    <ImageInput
                        image={image}
                        handleFileSelect={(files) => setImage(files[0])}
                        onDelete={() => setImage(null)}
                        positionRatio={imagePosition}
                        onSetPosition={setImagePosition}
                        className="w-full max-w-[200px]"
                        aspectRatio={AMBASSADOR_IMAGE_ASPECT_RATIO}
                    />
                </div>
            </div>
        </Popup>
    )
}