import AmbassadorCards from "@/js/Components/AmbassadorCards";
import DonationForm from "@/js/Components/DonationForm";
import DonationsTable from "@/js/Components/DonationsTable";
import { Image } from "@/js/Components/Image";
import Loader from "@/js/Components/Loader";
import StaticProgressBar from "@/js/Components/StaticProgressBar";
import { useCampaign } from "@/js/Providers/Public/CampaignProvider";
import { CAMPAIGN_IMAGE_ASPECT_RATIO, toCurrency } from "@/js/common";
import { usePublicCampaignAmbassadors, usePublicCampaignDonations } from "@/js/resources";
import React, { useMemo } from "react";
import { useParams } from "react-router";

export default function Campaign() {
    const { campaignId } = useParams();
    const [campaign] = useCampaign();
    const [ambassadors, { loading: ambassadorsLoading }] = usePublicCampaignAmbassadors({
        params: useMemo(() => ({
            campaign: campaignId,
        }), [campaignId])
    });

    const [donations] = usePublicCampaignDonations({
        params: useMemo(() => ({
            campaign: campaignId,
        }), [campaignId]),
    });

    return (
        <div className="flex flex-col flex-1 pt-5 pb-12 max-w-[1200px] self-center w-full">
            {campaign ? (
                <div className="flex flex-col gap-12 flex-1">
                    <div className="flex flex-col gap-8" >
                        <Image
                            resourceImage={campaign.image ?? undefined}
                            className="w-full"
                            aspectRatio={CAMPAIGN_IMAGE_ASPECT_RATIO}
                        />
                        <div className="flex flex-col gap-3">
                            <h1 className="hd-s text-primary">{campaign.name}</h1>
                            <span className="bd-l whitespace-pre-wrap text-justify">{campaign.description}</span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-5">
                        <h2 className="ttl-l text-primary">Eddigi adományok</h2>
                        <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
                            <div className="flex flex-col gap-3">
                                <div className="flex justify-between gap-3">
                                    <span>Összesen:</span>
                                    <span>{toCurrency(campaign.donation_amount)}</span>
                                </div>
                                <div className="flex justify-between gap-3">
                                    <span>Célösszeg:</span>
                                    <span>{toCurrency(campaign.donation_goal)}</span>
                                </div>
                            </div>
                            <div className="flex flex-1" >
                                <StaticProgressBar progress={campaign.donation_amount / campaign.donation_goal * 100} variant="primary" />
                            </div>
                        </div>
                        {donations.length > 0 && <DonationsTable donations={donations} maxEntries={5} showDonee={ambassadors.length > 0} />}
                    </div>
                    {!ambassadorsLoading && (ambassadors.length > 0 ? (
                        <div className="flex flex-col gap-8">
                            <h1 className="ttl-l text-primary">Nagykövetek</h1>
                            <AmbassadorCards ambassadors={ambassadors} loading={ambassadorsLoading} isPublic />
                        </div>
                    ) : (
                        campaign.active && <DonationForm />
                    ))}
                </div>
            ) : (
                <div className="flex flex-col flex-1 items-center justify-center">
                    <Loader className="w-12" />
                </div>
            )}
        </div>
    )
}