import LeftIcon from "@/svg/chevron-left.svg?react";
import RightIcon from "@/svg/chevron-right.svg?react";
import React, { useEffect, useMemo, useState } from "react";
import SimpleBar from "simplebar-react";
import { toCurrency } from "../common";
import { RecurringDonation } from "../resources";
import IconButton from "./IconButton";


export default function RecurringDonationsTable({ donations, maxEntries }: {
    donations: RecurringDonation[],
    maxEntries: number,
}) {
    const [page, setPage] = useState(0);
    const maxPages = useMemo(() => Math.ceil(donations.length / maxEntries), [donations, maxEntries]);
    useEffect(() => {
        if (page >= maxPages && maxPages > 0) {
            setPage(maxPages - 1);
        }
    }, [maxPages, setPage, page]);
    const donationsToDisplay = useMemo(() => donations.slice(page * maxEntries, (page + 1) * maxEntries), [donations, page, maxEntries]);
    return (
        donations.length > 0 && <SimpleBar className="rounded-lg max-w-full w-full overflow-x-auto">
            <table className="text-nowrap">
                <thead className="table-head">
                    <tr>
                        <th colSpan={3}>Rendszeres adományok</th>
                    </tr>
                </thead>
                <tbody>
                    {donationsToDisplay.map((donation) => (
                        <tr key={donation.id} className="table-tr">
                            <td className="w-full">{donation.donor_name ?? "Anonim"}</td>
                            <td>{toCurrency(donation.donation_amount)}</td>
                            <td>{donation.created_at.toLocaleDateString("hu", {
                                year: "numeric",
                                month: "long",
                            })} - {donation.end_at ? donation.end_at.toLocaleDateString("hu", {
                                year: "numeric",
                                month: "long",
                            }) : ""}</td>
                        </tr>
                    ))}
                </tbody>
                {maxPages !== 1 && (
                    <tfoot className="table-foot">
                        <tr>
                            <td colSpan={3}>
                                <div className="flex justify-end items-center gap-2">
                                    <IconButton onClick={() => setPage(page - 1)} disabled={page === 0} variant="filled" compact>
                                        <LeftIcon />
                                    </IconButton>
                                    <span className="bd-s">{page + 1} / {maxPages}</span>
                                    <IconButton onClick={() => setPage(page + 1)} disabled={page === maxPages - 1} variant="filled" compact>
                                        <RightIcon />
                                    </IconButton>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                )}
            </table>
        </SimpleBar>
    )
}