import BackButton from "@/js/Components/BackButton";
import Loader from "@/js/Components/Loader";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Navigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { route } from "ziggy-js";

export default function CampaignTemplatePreview() {
    const { campaignId } = useParams();
    const [searchParams] = useSearchParams();
    const template = useMemo(() => searchParams.get("template"), [searchParams]);
    const [html, setHtml] = useState<string>();
    useEffect(() => {
        if (!campaignId || !template) return;
        axios.get<string>(route("campaigns.template.preview", { campaign: campaignId, template })).then(({ data }) => {
            setHtml(data);
        });
    }, [campaignId, template, setHtml]);

    if (!template) return <Navigate to={`/app/campaigns/${campaignId}`} />;

    return (
        <div className="flex flex-col grow gap-3 py-3">
            <div className="flex items-center gap-2">
                <BackButton to={`/app/campaigns/${campaignId}`} />
                <h1 className="hd-s">E-mail előnézet</h1>
            </div>

            {html ? <iframe
                className="w-full h-screen"
                srcDoc={html}
                title="Campaign Template Preview"
            /> : <Loader className="size-10 self-center" />}
        </div>
    );
}