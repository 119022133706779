import { Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import { useMemo } from "react";
import { stripePromise, tailwindToHex } from "../common";

export default function useStripeElementsProps(options?: StripeElementsOptions, dependencies: React.DependencyList = []) {

    const styles = getComputedStyle(document.body);

    const props: {
        stripe: PromiseLike<Stripe | null> | Stripe | null
        options: StripeElementsOptions,
    } = useMemo(() => ({
        stripe: stripePromise,
        options: {
            currency: "huf",
            locale: "hu",
            appearance: {
                theme: "flat",
                variables: {
                    fontFamily: "Roboto, sans-serif",
                    colorPrimary: tailwindToHex(styles.getPropertyValue("--color-primary")),
                    colorBackground: tailwindToHex(styles.getPropertyValue("--color-surface")),
                    colorDanger: tailwindToHex(styles.getPropertyValue("--color-error")),
                    colorText: tailwindToHex(styles.getPropertyValue("--color-on-surface-variant")),
                    borderRadius: "12px",
                },
                rules: {
                    '.Input': {
                        height: "50px",
                        border: `1.5px solid ${tailwindToHex(styles.getPropertyValue("--color-outline"))}`,
                        fontSize: "15px",
                    },
                    '.Input--invalid': {
                        border: 'none',
                    },
                    '.Input:focus': {
                        borderColor: tailwindToHex(styles.getPropertyValue("--color-primary")),
                        boxShadow: "none",
                    },
                    '.Input:hover': {
                        borderColor: tailwindToHex(styles.getPropertyValue("--color-on-surface")),
                    },
                    '.Label': {
                        fontWeight: "500",
                        fontSize: "15px",
                        color: tailwindToHex(styles.getPropertyValue("--color-on-surface-variant")),
                        marginBottom: "7px",
                    },
                    '.AccordionItem': {
                        backgroundColor: tailwindToHex(styles.getPropertyValue("--color-surface-container")),
                        border: `1px solid ${tailwindToHex(styles.getPropertyValue("--color-outline"))}`,
                        padding: "15px",
                    },
                },
            },
            ...options,
        }
    }), [styles, ...dependencies]);

    return props;
}