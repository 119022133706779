import { AxiosError } from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router";

export default function useRedirectOnAxiosError(error: AxiosError | null) {
    const navigate = useNavigate();
    useEffect(() => {
        switch (error?.response?.status) {
            case 404:
                navigate("/404");
                break;
            case 403:
                navigate("/403");
                break;
        }
    }, [error?.response?.status]);
}