import Button from "@/js/Components/Form/Button";
import PasswordInput from "@/js/Components/Form/PasswordInput";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import axios from "axios";
import classNames from "classnames";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { route } from "ziggy-js";

interface Submit {
    password: string;
    password_confirmation: string;
}

export default function ResetPassword() {
    const [searchParams] = useSearchParams();
    const [successfulReset, setSuccessfulReset] = useState(false);
    const form = useForm<Submit>();
    const password = form.watch("password");

    const handleSubmit: SubmitHandler<Submit> = async (data) => {
        await axios.post(route("users.reset-password"), {
            ...data,
            token,
            email
        });
        setSuccessfulReset(true);
    }

    
    const token = searchParams.get("token");
    const encodedEmail = searchParams.get("email");


    if (!token || !encodedEmail) {
        return <Navigate to="/login" />
    }

    const email = decodeURIComponent(encodedEmail);

    return (
        <div className="flex flex-col gap-3 justify-self-stretch flex-1 p-10">
            {successfulReset ? (
                <h1 className="ttl-l text-center">Sikeresen visszaállítottuk a jelszavad. Kattints az alábbi gombra a bejelentkezéshez!</h1>
            ) : (
                <div className="flex flex-col gap-5 bg-surface-container p-5 rounded-2xl max-w-[500px] w-full self-center">
                    <h2 className="ttl-m">Jelszóemlékeztető kérése</h2>
                    <Form form={form} onSubmit={handleSubmit} className="flex flex-col gap-4 px-1 ">
                        <PasswordInput 
                            label="Új jelszó" 
                            name="password"
                            options={{
                                required: "Kötelező mező",
                                minLength: {
                                    value: 8,
                                    message: "A jelszónak legalább 8 karakter hosszúnak kell lennie"
                                }
                            }} 
                        />
                        <PasswordInput 
                            label="Új jelszó megerősítése" 
                            name="password_confirmation"
                            options={{
                                required: "Kötelező mező",
                                validate: {
                                    matches: (value) => value === password || "A két jelszó nem egyezik"
                                }
                            }} 
                        />
                        <div className="flex justify-center">
                            <Button variant="filled" submit>Új jelszó beállítása</Button>
                        </div>
                    </Form>
                </div>
            )}
            <div className="flex justify-center">
                <Link className={classNames("interactive-bg-surface p-2 rounded-md", {
                    "!interactive-bg-primary": successfulReset
                })} to="/login">Vissza a bejelentkezéshez</Link>
            </div>
        </div>
    )
}