import UploadIcon from "@/svg/upload.svg?react";
import { isNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useMeasuring from "../Hooks/MeasuringHook";
import BaseFileInput, { FileInputRef } from "./BaseFileInput";

function stopPropagation(e: Event) {
    e.stopPropagation();
    e.preventDefault();
}

export default function FileDropzone({
    onSelectFile,
    multiple,
    style,
    className,
    aspectRatio,
    axisSet,
}: {
    onSelectFile: (files: File[]) => void;
    multiple?: boolean;
    style?: React.CSSProperties;
    className?: string;
    aspectRatio?: number;
    axisSet?: "x" | "y";
}) {

    const inputRef = useRef<FileInputRef>(null);
    const [fileDragActive, setFileDragActive] = useState(false);
    const dragEventCounter = useRef(0);
    const [dropzoneRef, { width: renderedWidth, height: renderedHeight }] = useMeasuring<HTMLDivElement>();

    const [width, height] = useMemo(() => {
        if (aspectRatio === 0 || aspectRatio === undefined) {
            return [renderedWidth, renderedHeight];
        }
        if (axisSet === "x") {
            return [renderedWidth, renderedWidth / aspectRatio];
        }
        return [renderedHeight * aspectRatio, renderedHeight];
    }, [renderedWidth, renderedHeight, aspectRatio, axisSet]);

    useEffect(() => {
        if (isNotNull(dropzoneRef.current)) {
            const handleDrop = (e: DragEvent) => {
                stopPropagation(e);
                if (e.dataTransfer) {
                    onSelectFile([...e.dataTransfer.files]);
                }
                setFileDragActive(false);
                dragEventCounter.current = 0;
            }
            const handleDragEnter = (e: DragEvent) => {
                dragEventCounter.current++;
                stopPropagation(e);
                setFileDragActive(true);
            }
            const handleDragLeave = (e: DragEvent) => {
                dragEventCounter.current--;
                stopPropagation(e);
                if (dragEventCounter.current === 0) {
                    setFileDragActive(false);
                }
            }
            dropzoneRef.current.addEventListener("drop", handleDrop);
            dropzoneRef.current.addEventListener("dragenter", handleDragEnter);
            dropzoneRef.current.addEventListener("dragleave", handleDragLeave);
            dropzoneRef.current.addEventListener("dragover", stopPropagation);

            return () => {
                dropzoneRef.current?.removeEventListener("drop", handleDrop);
                dropzoneRef.current?.removeEventListener("dragenter", handleDragEnter);
                dropzoneRef.current?.removeEventListener("dragleave", handleDragLeave);
                dropzoneRef.current?.removeEventListener("dragover", stopPropagation);
            }
        }
    }, [dropzoneRef, onSelectFile, dragEventCounter, setFileDragActive]);

    return (
        <div
            style={{
                aspectRatio,
                ...style,
            }}
            ref={dropzoneRef}
            className={classNames("flex flex-col items-center justify-around bg-secondary-container rounded-[30px] p-8 bd-l cursor-pointer text-center shrink-0", className, {
                "bd-m p-3": Math.min(width, height) <= 200,
                "bg-tertiary-container [&_svg]:!fill-on-tertiary-container": fileDragActive,
            })}
            onClick={() => inputRef.current?.open()}
        >
            <UploadIcon className="h-[40%] fill-on-secondary-container" />
            <span>Húzd ide a képet vagy kattints a feltöltéshez</span>
            <BaseFileInput onSelected={onSelectFile} ref={inputRef} accept="image/*" multiple={multiple} />
        </div>
    )
}