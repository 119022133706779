import BackButton from "@/js/Components/BackButton";
import Loader from "@/js/Components/Loader";
import { useOrganization } from "@/js/Layouts/App";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { route } from "ziggy-js";

export default function OrganizationTemplatePreview() {
    const [html, setHtml] = useState<string>();
    const [organization, { loading }] = useOrganization();
    useEffect(() => {
        if (!organization?.id) return;
        axios.get<string>(route("organizations.template.preview", { organization: organization.id })).then(({ data }) => {
            setHtml(data);
        });
    }, [setHtml, organization?.id]);

    return (
        <div className="flex flex-col grow gap-3 py-3">
            <div className="flex items-center gap-2">
                <BackButton to="/app/organization" />
                <h1 className="hd-s">E-mail előnézet</h1>
            </div>

            {html ? <iframe
                className="w-full h-screen"
                srcDoc={html}
                title="Organization Template Preview"
            /> : <Loader className="size-10 self-center" />}
        </div>
    );
}