import React from "react";

export default function TableEmptyPlaceholder({
    colSpan = 3,
}: {
    colSpan?: number
}) {
    return (
        <tr>
            <td colSpan={colSpan}>
                <div className="flex gap-3">
                    <span>Itt még nincs semmi...</span>
                </div>
            </td>
        </tr>
    )
}