import DeleteIcon from "@/svg/trash.svg?react";
import UploadIcon from "@/svg/upload.svg?react";
import { useDisabled } from "@enymo/react-form-component";
import useHybridInput from "@enymo/react-hybrid-input-hook";
import classNames from "classnames";
import React, { useMemo, useRef } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import BaseFileInput, { BaseFileInputProps, FileInputRef } from "../BaseFileInput";
import IconButton from "../IconButton";

export interface FileInputProps extends Omit<BaseFileInputProps, "onSelected"> {
    label?: string;
    className?: string;
    disabled?: boolean;
    name?: string;
    options?: RegisterOptions;
    value?: File[];
    onSelected?(files: File[]): void;
    fileNames?: string[] | string;
}

export default function FileInput({
    label,
    className,
    disabled: disabledProp,
    name,
    options,
    value: externalValue,
    onSelected: externalOnChange,
    multiple,
    fileNames: fileNamesProp,
    ...props
}: FileInputProps) {
    const form = useFormContext();
    const inputRef = useRef<FileInputRef>(null);
    const { error, onChange, value } = useHybridInput({ name, externalOnChange, externalValue, options });
    const disabledContext = useDisabled();
    const disabled = disabledProp ?? disabledContext;

    const fileNames = useMemo(() => fileNamesProp === undefined ? [] : Array.isArray(fileNamesProp) ? fileNamesProp : [fileNamesProp], [fileNamesProp]);

    const hasFile = useMemo(() => (value === undefined && fileNames.length > 0) || (value && value.length > 0), [value, fileNames]);

    const handleRemove = () => {
        onChange([]);
        if (name) {
            form?.setValue(name, null);
        }
    }

    return (
        <div className="flex flex-col gap-1">
            <div className={classNames("flex flex-col gap-2 bg-surface-container border border-outline rounded-lg pt-2 px-3 pb-2", {
                "border border-error": error !== undefined,
            })}>
                <span className={classNames("lb-l", {
                    "text-error": error !== undefined,
                })}>{label}</span>
                {hasFile ? (
                    <div className="flex gap-2 items-center">
                        <div className="flex flex-col gap-1">
                            {((value === null ? [] : value)?.map(file => file.name) ?? fileNames)?.map((file, index) => (
                                <span className="bd-m" key={index}>{file}</span>
                            ))}
                        </div>
                        <IconButton
                            variant="error"
                            onClick={handleRemove}
                            disabled={disabled}
                            compact
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ) : (
                    <div className="flex gap-2 items-center">
                        <span className="bd-m">{`${multiple ? "Fájlok" : "Fájl"} kiválasztása`}</span>
                        <IconButton 
                            variant="filled-tonal"
                            onClick={() => inputRef.current?.open()}
                            disabled={disabled}
                            compact
                        >
                            <UploadIcon />
                        </IconButton>
                    </div>
                )}
            </div>
            <span className="bd-s text-error ml-1">{error?.message}</span>
            <BaseFileInput onSelected={onChange} ref={inputRef} multiple={multiple} {...props} />
        </div>
    )
}