import useRedirectOnAxiosError from "@/js/Hooks/AxiosErrorRedirectHook";
import { useAlert } from "@/js/Layouts/Public";
import { Campaign, usePublicOrganizationCampaigns } from "@/js/resources";
import { createRequiredContext } from "@enymo/react-better-context";
import { ReturnSingle } from "@enymo/react-resource-hook";
import React, { useMemo } from "react";
import { Outlet, useNavigate, useParams } from "react-router";

const [Provider, useCampaign] = createRequiredContext<[Campaign | null, ReturnSingle<Campaign, Campaign>]>("The Campaign must be provided to use the campaign.")

export { useCampaign };

export default function CampaignProvider() {
    const navigate = useNavigate();
    const { organizationId, campaignId } = useParams();
    const [campaign, actions] = usePublicOrganizationCampaigns({
        id: Number(campaignId),
        params: useMemo(() => ({
            organization: organizationId
        }), [organizationId]),
    });

    useRedirectOnAxiosError(actions.error);

    
    useAlert((campaign?.active ?? true) ? null : {
        type: "error",
        title: "A kampány nem aktív.",
        message: "Így nem fogad adományokat."
    })

    return (
        <Provider value={[campaign, actions]}>
            <Outlet />
        </Provider>
    )
}