import React from "react";
import Loader from "./Loader";

export default function TableLoadingPlaceholder({
    colSpan = 3,
}: {
    colSpan?: number
}) {
    return (
        <tr>
            <td colSpan={colSpan}>
                <div className="flex gap-3">
                    <Loader className="w-5" />
                    <span>Adatok betöltése...</span>
                </div>
            </td>
        </tr>
    )
}