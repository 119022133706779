import FileDropzone from "@/js/Components/FileDropzone";
import Button from "@/js/Components/Form/Button";
import Input from "@/js/Components/Form/Input";
import IconButton from "@/js/Components/IconButton";
import { Image } from "@/js/Components/Image";
import { ImageInput } from "@/js/Components/ImageInput";
import Loader from "@/js/Components/Loader";
import PropertyForm from "@/js/Components/PropertyForm";
import TableEmptyPlaceholder from "@/js/Components/TableEmptyPlaceholder";
import TableLoadingPlaceholder from "@/js/Components/TableLoadingPlaceholder";
import { useOrganization } from "@/js/Layouts/App";
import { ORGANIZATION_GALLERY_IMAGE_ASPECT_RATIO, ORGANIZATION_IMAGE_ASPECT_RATIO, ORGANIZATION_THANKING_IMAGE_ASPECT_RATIO } from "@/js/common";
import { ApiInvitationToken, invitationTokenTransformer, useOrganizationInvitationTokens, useOrganizationPositionableImages, useOrganizationUsers } from "@/js/resources";
import OpenInNewIcon from "@/svg/open-in-new.svg?react";
import { Form, SubmitHandler, setFormValues } from "@enymo/react-form-component";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import SimpleBar from "simplebar-react";
import { route } from "ziggy-js";


interface OrganizationContactSubmit {
    name: string,
    email: string,
    gdpr_url: string,
    website: string,
    facebook: string,
    instagram: string,
}

interface OrganizationInformationSubmit {
    description_short: string,
    description_long: string,
}

interface DonationThankingSubmit {
    donation_success_message: string,
    donation_email_template: FileList | null,
}

interface UserInvitationSubmit {
    emails: string,
}

export default function Organization() {
    const [organization, { update: updateOrganization }] = useOrganization();
    const autoRefresh = useMemo(() => !!(organization?.id), [organization?.id]);
    const params = useMemo(() => ({
        organization: organization?.id,
    }), [organization?.id]);
    const [users, { loading: usersLoading }] = useOrganizationUsers({
        params,
        autoRefresh,
    });
    const [positionableImages, {
        store: storePositionableImage,
        update: updatePositionableImage,
        destroy: destroyPositionableImage,
        loading: positionableImagesLoading,
    }] = useOrganizationPositionableImages({
        params,
        autoRefresh,
    });
    const [invitationTokens, { 
        loading: invitationTokensLoading, 
        destroy: destroyInviationToken, 
        store: storeInvitationToken,
    }] = useOrganizationInvitationTokens({
        params,
        autoRefresh,
    });

    const [contactEditMode, setContactEditMode] = useState(false);
    const contactForm = useForm<OrganizationContactSubmit>();
    const [informationEditMode, setInformationEditMode] = useState(false);
    const organizationInformationForm = useForm<OrganizationInformationSubmit>();
    const [donationThankingEditMode, setDonationThankingEditMode] = useState(false);
    const donationSuccessForm = useForm<DonationThankingSubmit>();
    const userInvitationForm = useForm<UserInvitationSubmit>();

    useEffect(() => {
        if (!organization) return;
        setFormValues(contactForm, {
            email: organization.email,
            name: organization.name,
            website: organization.website,
            facebook: organization.facebook,
            instagram: organization.instagram,
        });
        setFormValues(organizationInformationForm, {
            description_short: organization.description_short,
            description_long: organization.description_long,
        });
        setFormValues(donationSuccessForm, {
            donation_success_message: organization.donation_success_message,
        });
    }, [organization]);

    const handleContactInfoSubmit: SubmitHandler<OrganizationContactSubmit> = async (data) => {
        await updateOrganization(data);
        setContactEditMode(false);
    }

    const handleDescriptionSubmit: SubmitHandler<OrganizationInformationSubmit> = async (data) => {
        await updateOrganization({
            ...data,
        });
        setInformationEditMode(false);
    }

    const handleDonationSuccessSubmit: SubmitHandler<DonationThankingSubmit> = async (data) => {
        await updateOrganization({
            ...data,
            donation_email_template: data.donation_email_template === null ? null : data.donation_email_template?.[0] ?? undefined,
        });
        setDonationThankingEditMode(false);
    }

    const [logoUploadProgress, setLogoUploadProgress] = useState<number | undefined>(undefined);

    const handleUploadLogo = (files: File[]) => {
        if (files.length === 0) return;
        const file = files[0];
        updateOrganization({
            image: { file },
        }, 'immediate', {
            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.progress ?? 0) * 100;
                if (progress < 100) {
                    setLogoUploadProgress(progress);
                }
                else {
                    setLogoUploadProgress(undefined);
                }
            }
        });
    }
    
    const handleUploadDonationSuccessImage = (files: File[]) => {
        if (files.length === 0) return;
        const file = files[0];
        updateOrganization({
            donation_success_image: { file },
        }, 'immediate');
    }

    const handleUserInvitationSubmit: SubmitHandler<UserInvitationSubmit> = async (data) => {
        const tokens = (await axios.post<ApiInvitationToken[]>(route("organizations.invite-users", { organization: organization!.id }), {
            emails: data.emails.split(",").map(email => email.trim()),
        })).data;

        tokens.forEach((token) => storeInvitationToken(invitationTokenTransformer(token), 'local-only'));
        userInvitationForm.reset();
    }


    return (
        <div className="flex flex-col gap-8 py-10 max-w-[1200px] w-full self-center">
            {!organization ? (
                <div className="flex flex-1 items-center justify-center">
                    <Loader className="w-12" />
                </div>
            ) : (
                <>
                    <div className="flex flex-col gap-3">
                        <h2 className="ttl-l text-primary">Kapcsolati információk</h2>
                        <div className="flex flex-col grow gap-10 sm:flex-row">
                            <ImageInput
                                resourceImage={organization.image ?? undefined}
                                uploadProgress={logoUploadProgress}
                                handleFileSelect={handleUploadLogo}
                                onDelete={() => updateOrganization({ image: null }, 'immediate')}
                                onSetPosition={(position) => updateOrganization({
                                    image: {
                                        ...organization.image,
                                        position
                                    },
                                })}
                                aspectRatio={ORGANIZATION_IMAGE_ASPECT_RATIO}
                                className="w-full h-min sm:w-[300px]"
                            />
                            <Form form={contactForm} onSubmit={handleContactInfoSubmit} className="flex-1">
                                <div className="flex flex-col gap-3 flex-1">
                                    <PropertyForm
                                        editable={contactEditMode}
                                        properties={[
                                            {
                                                label: "Név",
                                                value: organization.name,
                                                input: {
                                                    name: "name",
                                                    label: "{useLabel}",
                                                    options: { required: "Kötelező mező" },
                                                },
                                            },
                                            {
                                                label: "E-mail",
                                                value: organization.email,
                                                input: {
                                                    name: "email",
                                                    label: "{useLabel}",
                                                    options: { required: "Kötelező mező" },
                                                },
                                            },
                                            {
                                                label: "Adatvédelmi nyilatkozat",
                                                value: organization.gdpr_url,
                                                input: {
                                                    name: "gdpr_url",
                                                    label: "{useLabel}",
                                                },
                                            },
                                            {
                                                label: "Weboldal",
                                                value: organization.website,
                                                input: {
                                                    name: "website",
                                                    label: "{useLabel}",
                                                },
                                            },
                                            {
                                                label: "Facebook",
                                                value: organization.facebook,
                                                input: {
                                                    name: "facebook",
                                                    label: "{useLabel}",
                                                },
                                            },
                                            {
                                                label: "Instagram",
                                                value: organization.instagram,
                                                input: {
                                                    name: "instagram",
                                                    label: "{useLabel}",
                                                },
                                            },
                                        ]}
                                    />
                                    <div className="flex justify-end gap-3">
                                        <Button variant="outline" onClick={() => setContactEditMode(!contactEditMode)}>
                                            {contactEditMode ? "Mégse" : "Szerkesztés"}
                                        </Button>
                                        {contactEditMode && (
                                            <Button variant="filled-tonal" submit>Mentés</Button>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className="flex flex-col gap-7">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col gap-1">
                                <h2 className="ttl-l text-primary">Szervezeti adományozási oldal</h2>
                            </div>
                            <IconButton variant="filled-tonal" to={`/public/organizations/${organization.id}`} linkType="new-tab">
                                <OpenInNewIcon />
                            </IconButton>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="ttl-s">Képek</h3>
                            <SimpleBar>
                                <div className="flex gap-3">
                                    {positionableImagesLoading ? (
                                        <Loader className="w-12" />
                                    ) : positionableImages?.map(image => (
                                        <Image
                                            key={image.id}
                                            resourceImage={image}
                                            aspectRatio={ORGANIZATION_GALLERY_IMAGE_ASPECT_RATIO}
                                            className="w-full max-w-[300px] h-min"
                                            onSetPosition={(position) => updatePositionableImage(image.id, { position })}
                                            onDelete={() => destroyPositionableImage(image.id, 'immediate')}
                                            editable
                                        />
                                    ))}
                                    <FileDropzone
                                        onSelectFile={(files) => storePositionableImage({ file: files[0] })}
                                        className="w-full max-w-[300px] h-min"
                                        aspectRatio={ORGANIZATION_GALLERY_IMAGE_ASPECT_RATIO}
                                    />
                                </div>
                            </SimpleBar>
                        </div>
                        <Form form={organizationInformationForm} onSubmit={handleDescriptionSubmit} className="flex flex-col gap-5">
                            <PropertyForm
                                editable={informationEditMode}
                                properties={[
                                    {
                                        label: "Rövid leírás",
                                        value: organization.description_short,
                                        cutLength: 200,
                                        input: {
                                            name: "description_short",
                                            label: "{useLabel}",
                                            type: "textarea",
                                            inputClassName: "min-h-[100px] resize-y",
                                            options: { required: "Kötelező mező" }
                                        }
                                    },
                                    {
                                        label: "Részletes leírás",
                                        value: organization.description_long,
                                        cutLength: 300,
                                        input: {
                                            name: "description_long",
                                            label: "{useLabel}",
                                            type: "textarea",
                                            inputClassName: "min-h-[300px] resize-y",
                                            options: { required: "Kötelező mező" }
                                        }
                                    }
                                ]}
                            />
                            <div className="flex justify-end gap-3">
                                <Button variant="outline" onClick={() => setInformationEditMode(!informationEditMode)}>
                                    {informationEditMode ? "Mégse" : "Szerkesztés"}
                                </Button>
                                {informationEditMode && (
                                    <Button variant="filled-tonal" submit>Mentés</Button>
                                )}
                            </div>
                        </Form>
                        <div className="flex flex-col gap-6">
                            <h2 className="ttl-m text-primary">Adományozás utáni köszönő oldal</h2>
                            <ImageInput
                                resourceImage={organization.donation_success_image ?? undefined}
                                onSetPosition={(position) => updateOrganization({
                                    donation_success_image: {
                                        ...organization.donation_success_image,
                                        position
                                    },
                                })}
                                handleFileSelect={handleUploadDonationSuccessImage}
                                onDelete={() => updateOrganization({ donation_success_image: null }, 'immediate')}
                                aspectRatio={ORGANIZATION_THANKING_IMAGE_ASPECT_RATIO}
                                className="w-full sm:w-3/4 h-min self-center"
                            />
                            <Form form={donationSuccessForm} onSubmit={handleDonationSuccessSubmit} className="flex flex-col gap-5">
                                <PropertyForm
                                    editable={donationThankingEditMode}
                                    properties={[
                                        {
                                            label: "Köszönő oldal szöveg",
                                            value: organization.donation_success_message,
                                            input: {
                                                name: "donation_success_message",
                                                label: "{useLabel}",
                                                type: "textarea",
                                                inputClassName: "min-h-[300px] resize-y",
                                                options: { required: "Kötelező mező" }
                                            }
                                        },
                                        {
                                            label: "Köszönő e-mail",
                                            value: organization.donation_email_template?.name,
                                            input: {
                                                inputType: "file",
                                                name: "donation_email_template",
                                                label: "{useLabel}",
                                                multiple: false,
                                                accept: "text/html",
                                                fileNames: organization.donation_email_template?.name,
                                                previewUrl: `/app/organization/preview`,
                                            }
                                        },
                                    ]}
                                />
                                <div className="flex justify-end gap-3">
                                    <Button variant="outline" onClick={() => setDonationThankingEditMode(!donationThankingEditMode)}>
                                        {donationThankingEditMode ? "Mégse" : "Szerkesztés"}
                                    </Button>
                                    {donationThankingEditMode && (
                                        <Button variant="filled-tonal" submit>Mentés</Button>
                                    )}
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3">
                        <h2 className="ttl-l text-primary">Kezelők hozzáadása</h2>
                        <span style={{
                            color: "var(--on-surface)",
                            fontSize: "12px"
                        }}>Add meg az e-maileket vesszővel elválasztva</span>
                        <Form form={userInvitationForm} onSubmit={handleUserInvitationSubmit}>
                            <div className="flex flex-col gap-3">
                                <Input type="textarea" name="emails" inputClassName="h-[200px]" />
                                <div className="flex justify-end">
                                    <Button variant="filled-tonal" submit>Hozzáadás</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="flex flex-col gap-5">
                        <h2 className="ttl-l text-primary">A szervezet kezelői</h2>
                        <SimpleBar className="rounded-lg max-w-full w-full overflow-x-auto">
                            <table className="w-full">
                                <thead className="table-head">
                                    <tr>
                                        <th>Név</th>
                                        <th>E-mail</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {usersLoading ? (
                                        <TableLoadingPlaceholder colSpan={2} />
                                    ) : users?.map(user => (
                                        <tr key={user.id} className="table-tr">
                                            <td>{`${user.last_name} ${user.first_name}`}</td>
                                            <td>{user.email}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </SimpleBar>
                    </div>
                    <div className="flex flex-col gap-5">
                        <h2 className="ttl-l text-primary">Függőben lévő meghívók</h2>
                        <SimpleBar className="rounded-lg max-w-full w-full overflow-x-auto">
                            <table className="w-full">
                                <thead className="table-head">
                                    <tr>
                                        <th>E-mail</th>
                                        <th>Érvényesség</th>
                                        <th>Törlés</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invitationTokensLoading ? (
                                        <TableLoadingPlaceholder colSpan={3} />
                                    ) : invitationTokens?.length > 0 ? invitationTokens?.map(token => (
                                        <tr key={token.id} className="table-tr">
                                            <td>{token.email}</td>
                                            <td>{token.valid_until.toLocaleDateString('hu') + " " + token.valid_until.toLocaleTimeString('hu')}</td>
                                            <td>
                                                <Button variant="outline" onClick={() => destroyInviationToken(token.id)}>
                                                    Törlés
                                                </Button>
                                            </td>
                                        </tr>
                                    )) : (
                                        <TableEmptyPlaceholder />
                                    )}
                                </tbody>
                            </table>
                        </SimpleBar>
                    </div>
                </>
            )}
        </div>
    )
}